<template>
  <div>
    <h3>30 April 2010 (0.183.8)</h3>
    <p>
      <u>Property</u>
      <br>- You should be able to place models on the entire property.
    </p>
    <p>
      <u>Namenator</u>
      <br>- The list of names has been updated.
    </p>
    <p>
      <u>Mail</u>
      <br>- Mail has been disabled.
    </p>
    <h3>29 April 2010 (0.183.7)</h3>
    <p>
      <u>Mail</u>
      <br>- User generated models cannot be sent in the mail.
    </p>
    <p>
      <u>Racing</u>
      <br>- Car modules no longer become stuck in the walls of Modular Car Build.
    </p>
    <h3>29 April 2010 (0.183.6)</h3>
    <p>
      <u>Missions</u>
      <br>- Mission giver icons should now update according to your mission status.
    </p>
    <p>
      <u>Racing</u>
      <br>- Racing maps are now loaded when you enter either Gnarled Forest or Nimbus Station.
    </p>
    <p>
      <u>Property</u>
      <br>- You are now able to place models anywhere on your property.
    </p>
    <p>
      <u>Trade</u>
      <br>- Faction tokens can’t be traded.
    </p>
    <h3>27 April 2010 (0.183.5)</h3>
    <p>
      <u>Racing</u>
      <br>- After landing a jump or grinding against a wall, the race car should no longer be pushed into the ground, stopped, pushed backwards, or flipped over.
    </p>
    <p>
      <u>Namenator</u>
      <br>- If a Namenator name is already taken, a message will state this and you will be able to go back a step in the naming process. - The Namenator lists have been edited.
    </p>
    <p>
      <u>Pets</u>
      <br>- Pieces in the pet taming minigames should now be selectable.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- The platform in the Pirate Brig area should no longer allow Minifigures to fall through it.
    </p>
    <h3>23 April 2010 (0.183.4)</h3>
    <p>
      <u>Racing</u>
      <br>- Landing in a “divot” in the track will no longer cause your car to become stuck. - The race car chassis should appear in the vendor window consistently.
    </p>
    <p>
      <u>Namenator</u>
      <br>- The Namenator should now allow Minifigures with just a 3-word suggested name and no custom name to be created. - The words in the Namenator have been edited.
    </p>
    <p>
      <u>Chat</u>
      <br>- Holding down TAB while the chat window is active will no longer cause the game to freeze.
    </p>
    <h3>21 April 2010 (0.183.3)</h3>
    <p>
      <u>Namenator</u>
      <br>- If you select “cancel” at the last step of name creation, you will be sent back to the screen that allows you to change your custom name. - Hitting “enter” after entering a custom name will now work the same as clicking the “play” button. - If a submitted name comes back as Unavailable, then you should still be able to go back and customize the Minifigure.
    </p>
    <p>
      <u>Property</u>
      <br>- After smashing your Minifigure on the Avant Gardens small property, it will rebuild on the property.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- Robot dogs will now appear in areas that won’t interfere with the Rocket Build area.
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- It you jump over the fence and into the water, you will respawn in a safe place.
    </p>
    <p>
      <u>Shooting Gallery</u>
      <br>- You should not become stuck in the Shooting Gallery. - Ranks are now displayed correctly on the leaderboard.
    </p>
    <p>
      <u>Survival</u>
      <br>- You should no longer become stuck in the Survival lobby.
    </p>
    <p>
      <u>Trade</u>
      <br>- Items can be dragged in and out of trade, and the trade window will update accordingly.
    </p>
    <h3>20 April 2010 (0.183.2)</h3>
    <p>
      <u>Launchpads</u>
      <br>- Car models cannot be used as “rockets” to travel between worlds anymore.
    </p>
    <p>
      <u>Race</u>
      <br>- There is a new race summary screen.
    </p>
    <p>
      <u>Shooting Gallery</u>
      <br>- You should no longer become stuck, if you are smashed while in the Shooting Gallery.
    </p>
    <p>
      <u>Trade</u>
      <br>- Items removed from trade will not display in either tester’s trade window.
    </p>
    <p>
      <u>Properties</u>
      <br>- For now, you can only visit your own property.
    </p>
    <p>
      <u>Pet Taming</u>
      <br>- “Wild” crabs and warthogs should now spawn in locations that will allow the pet taming minigame to load in playable areas.
    </p>
    <p>
      <u>Rocket Build</u>
      <br>- The “Done” check mark should now be in a location that is easier to see.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- You should no longer become stuck in the concert area entrance.
    </p>
    <p>
      <u>Options Menu/ Help Menu</u>
      <br>- The Options Menu and the Help Menu will no longer open every time you transition to another world.
    </p>
    <p>
      <u>Character Create</u>
      <br>- You should now be able to exit the naming process after receiving an error message.
    </p>
    <h3>17 April 2010 (0.183.1) </h3>
    <p>
      <u>Build Modes</u>
      <br>- You will no longer enter Brick Mode from trying to pick up a model.
    </p>
    <p>
      <u>Travel</u>
      <br>- When travelling between maps, testers no longer become stuck.
    </p>
    <p>
      <u>Pet Taming</u>
      <br>- The white piece for the Warthog game should be showing.
    </p>
    <p>
      <u>Racing</u>
      <br>- Testers with recommended spec machines should now be able to race against testers using high spec machines. - Nimbus Station race activator now points to Gnarled Forest race track.
    </p>
    <p>
      <u>Accessibility</u>
      <br>- Launchpad to Gnarled Forest has been unlocked. - Launchpad to Pet Cove has been unlocked. - Launchpad to private property from Nimbus station has been closed.
    </p>
    <p>
      <u>Logout</u>
      <br>- The game will no longer crash if you log out from the Options Menu.
    </p>
    <h3>13 April 2010 (0.183.0)</h3>
    <p>
      <u>Namenator</u>
      <br>- You can now select a three-word temporary name for new Minifigures. This name will appear above the Minifigure’s head, until their custom name is approved.
    </p>
    <p>
      <u>Build Modes</u>
      <br>- Changes have been made to the Esc functionality. - Property height restrictions are now enforced during Model and Brick Modes. - Exiting Brick Mode always correctly saves or restores models or bricks on the click stick.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- The mission flow has been changed.
    </p>
    <p>
      <u>Status Bar</u>
      <br>- The new status bar allows for unlimited addition of hearts and armor, without cluttering up the screen real estate.
    </p>
    <p>
      <u>Action Bar</u>
      <br>- The new action bar has a limit of ten slots, and will resize itself to accommodate screen real estate.
    </p>
    <p>
      <u>Racing</u>
      <br>- Starting positions moved to starting line. - Watch out, you now fly off the tracks where it looks like you can fly off the tracks. - When at a Race Activator, hitting the Shift key or dragging a completed modular car to the activator will activate the race. - The user interface has been updated.
    </p>
    <h3>19 April 2010 (0.182.8)</h3>
    <p>
      <u>Private Property</u>
      <br>- Testers can no longer go to others’ property.
    </p>
    <h3>13 April 2010 (0.182.7)</h3>
    <p>
      <u>Trade</u>
      <br>- Players are now able to trade more than once per test session.
    </p>
    <p>
      <u>User Interface</u>
      <br>- Anytime a user logs out or switches Minifigure, the UI will reset.
    </p>
    <p>
      <u>Build Modes</u>
      <br>- Models built to max height in Model Mode should now be able to load back into Brick Mode.
    </p>
    <h3>9 April 2010 (0.182.6)</h3>
    <p>
      <u>Action Bar</u>
      <br>- A new and improved version of the Action Bar is now available!
    </p>
    <p>
      <u>Pet Menu</u>
      <br>- The pet menu has been moved.
    </p>
    <p>
      <u>Mail</u>
      <br>- Tester cannot delete a message in mail, if it has an attachment. - Non-deletable, mission items and rockets cannot be attached.
    </p>
    <p>
      <u>Trade</u>
      <br>- The trade window cannot be opened while the mail window is open and vice versa.
    </p>
    <p>
      <u>Friends List</u>
      <br>- Testers can no longer ad themselves to their own friends/ ignore list.
    </p>
    <p>
      <u>General</u>
      <br>- The texture of Minifigures created without having entered the game has been improved.
    </p>
    <h3>8 April 2010 (0.182.5)</h3>
    <p>
      <u>Build Modes</u>
      <br>- A model built in Brick Mode to maximum height can be placed in Model Mode, as well as be loaded back into Brick Mode.
    </p>
    <p>
      <u>Chat</u>
      <br>- Newly created Minifigures have predictive chat.
    </p>
    <p>
      <u>Maps</u>
      <br>- The map in Avant Gardens Small Property now has the correct images.
    </p>
    <h3>7 April 2010 (0.182.4)</h3>
    <p>
      <u>Mail</u>
      <br>- Non-deletable elements such as rockets and mission items cannot be sent to other testers in the game. - Testers on your ignore list cannot send you mail.
    </p>
    <p>
      <u>Maps</u>
      <br>- When the Universe Map is open, the Minifigure will be stunned.
    </p>
    <p>
      <u>Shooting Gallery</u>
      <br>- Background and help screen have been corrected.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- Instruments should not disappear anymore, if someone disconnects while playing.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- Players cannot leave the map in the Racing Area anymore.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- The flags now appear correctly.
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- After successfully taming a pet, the new pet will now have a generic name assigned to it automatically, until its custom name gets approved.
    </p>
    <p>
      <u>Trade</u>
      <br>- You are now able to trade! - Non-deletable items such as the Thinking Hat cannot be traded. - User-generated models are also not tradeable.
    </p>
    <h3>6 April 2010 (0.182.3)</h3>
    <p>
      <u>Missions</u>
      <br>- The head-up display for mission goals no longer resets when a player exits and repatches.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- World boundaries should have the correct collision.
    </p>
    <h3>6 April 2010 (0.182.2)</h3>
    <p>
      <u>Maps</u>
      <br>- All zones should have the correct World Map and Min-map images.
    </p>
    <p>
      <u>Mail</u>
      <br>- Mail messages now have a 399 character limit.
    </p>
    <p>
      <u>Venture Explorer</u>
      <br>- An incorrectly placed arrow in the floor has been removed.
    </p>
    <p>
      <u>Brick Mode</u>
      <br>- If a tester attempts to place a brick higher than the allowed height (400 plates, or 133 bricks plus 1 plate), then the brick will flash red.
    </p>
    <h3>5 April 2010 (0.182.1) </h3>
    <p>
      <u>Build modes</u>
      <br>- Brick, Model and Modular build will no longer respond to the Escape key.
    </p>
    <p>
      <u>Chat</u>
      <br>- The maximum number of characters in the chatbox has been increased to 140.
    </p>
    <p>
      <u>Mail</u>
      <br>- Mail messages now have a 399 character limit.
    </p>
    <p>
      <u>Spawning</u>
      <br>- Minifigures no longer spawn in unusual locations, such as under the ground.
    </p>
    <h3>1 April 2010 (0.182.0)</h3>
    <p>
      <u>Pet Cove</u>
      <br>- Pet Cove is now open!
    </p>
    <p>
      <u>Mail</u>
      <br>- Send and receive mail from mailboxes throughout the world and on your property.
    </p>
    <p>
      <u>Paper Doll</u>
      <br>- The new, easy way to equip your Minifigure with clothing and items. It’s located on the left side of your open backpack.
    </p>
    <p>
      <u>World Map</u>
      <br>- Expand the minimap to make it full screen.
    </p>
    <p>
      <u>Minimap</u>
      <br>- Maps dynamically change the object types depending on what gear you’re wearing and whether you have a pet with you.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- The radar dish now destroys only one of the explosion points at a time.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- You should now bring your infected bricks for the Paradox Stamp mission to the Paradox Representative instead of going all the way back to Wisp. - Johnny Thunder has new side missions for you. - The prices for Faction Gear have been adjusted.
    </p>
    <p>
      <u>Brick Mode</u>
      <br>- The Brick Mode buttons and tabs have been revised. - Camera controls were added. - A model automatically saves when you exit Brick Mode.
    </p>
    <p>
      <u>Model Mode</u>
      <br>- You are now able to name and describe a model when you click on it. - Interactions with models have been revised. Gnarled Forest
    </p>
    <p>
      <u>Shooting Gallery</u>
      <br>- The period of time models appear during the reward displays has been reduced.
    </p>
  </div>
</template>
